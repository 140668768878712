import React from 'react';
import { withPrefix, Link } from 'gatsby';
import Layout from 'components/layout';

/**
 * NotFoundPage Functional Component.
 */
export default function NotFoundPage() {
    return (
        <Layout>
            <div
                className="wrapper text-center margin-top-double appear-when-ready"
                style={{ maxWidth: '76rem' }}
            >
                <img
                    src={withPrefix('/img/404.jpg')}
                    alt="Illustration d'Elodie Arete devant un tableau 404"
                />
                <h1 className="blue-title">Oops…</h1>
                <div className="regular-text margin-top-double">
                    La page que vous recherchez n’existe pas ou plus… n’hésitez
                    pas à{' '}
                    <a
                        className="grey-link"
                        href="mailto:elodie.arete@gmail.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="M'envoyer un email"
                    >
                        me contacter
                    </a>{' '}
                    pour m’avertir ou à retourner sur la page d’accueil pour
                    continuer votre visite :)
                </div>

                <Link
                    to="/"
                    className="button margin-top-double appear-when-ready"
                >
                    Accueil
                </Link>
            </div>
        </Layout>
    );
}

NotFoundPage.displayName = 'NotFoundPage';
